.TvDashboard {
    min-height: 100vh;
    background-color: white;
}

.Header {
    /* margin-top: 5px; */
    /* position: fixed; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-evenly; */
}

.Header h3,
.Header h4 {
    color: #254e6f;
    margin: 5px;
}

.stats {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 15%;
    max-width: 15%;
    min-width: fit-content;
}

.stats h2 {
    margin: 0;
    padding: 5px 15px 10px 15px;
    border-radius: 50%;
    font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
}

.machines {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 0px;
}

.machine_card {
    min-width: 300px;
    background-color: white;
    padding: 10px 2px 0 2px;
    width: 320px;
    border-radius: 10px;
    margin: 10px;
    border: 2px solid #C4C4C4;
    z-index: 2;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  */
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
}
.inLine {
    display: flex;
    flex-direction: row;
    align-Items: center;
}

.row2,
.row3 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

/* .machine_card h4,
.machine_card h5,
.machine_card h6,
.machine_card h1 {
    margin: 2px;
    color: black;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
} */

.line {
    margin: 1px
}

.signOut {
    text-align: center;
    font-size: 30px;
    display: block;
    color: #254e6f;
}

.com_name_mobile {
    display: none;
}


/* .row4 {
    display: 'flex';
    width: 100%;
    height: 20px;
    border-radius: 10px
} */



.TextMachName {
    font-family: "poppoins", sans-serif;
    font-size: 15px;
    color: black;
    font-weight: 700;
    margin: 0px;
}
.TextDevName{
    font-size: 13px;
    font-family: "poppoins", sans-serif;
    color: #49454F;
    margin: 0px;
    font-weight: 500
}
.machineTimeText{
    font-size: 32px;
    font-family:  "poppoins", sans-serif;
    font-weight: 700;
    color: black;
    margin: 5px 0;
}
.machineTimeText1{
    font-size: 18px;
    font-family:  "poppoins", sans-serif;
    color: black;
    margin: 5px 0;
}
.machineEff{
    font-size: 25px;
    font-family:  "poppoins", sans-serif;
    font-weight: 600;
    color: black;
    margin: 5px 0;
}
.shiftTime{
    margin: 1px;
    font-size: 12px;
    font-family:  "poppoins", sans-serif;
    font-weight: 600;
    color: #49454F;
}
.shiftTimedata{
    margin: 1px;
    font-size: 14px;
    font-family:  "poppoins", sans-serif;
    font-weight: 600;
    color: #49454F;
}


@media only screen and (min-width: 1141px) {
    /* .machine_card {
        width: auto;
    } */

}


@media only screen and (max-width: 600px) {
    .com_name {
        display: none;
    }

    .Header {
        justify-content: space-between;
    }

    .stats {
        width: 45%;
        max-width: 45%;
    }

    .com_name_mobile {
        display: block;
    }

    .com_name_mobile h3 {
        font-size: medium;
        color: #254e6f;
        /* text-align: center; */
        margin: 10px 20px;
    }

    .com_name_mobile h4 {
        font-size: small;
        color: #254e6f;
        margin: 10px 20px;
    }

    .machines {
        margin-top: 0;
    }


}